import { Box, keyframes } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { joinClasses } from "utils/muiUtils"

const useStyles = makeStyles(()=>({
    blokk: {
        fontFamily: 'blokk',
        fontSize: '30px',
        lineHeight: '22px',
        letterSpacing: '-15px',
        paddingRight: '15px',
    },
    'blokk-light': {
        color: '#eaeaea',
    },
    'blokk-dark': {
        color: '#abb0b8',
    }
}))

const dummyText = 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.'

export const BlokkText = ({children, color}) => {

    const classes = useStyles()

    color = color || 'light'

    const classNames = [classes.blokk]
    classNames.push(classes[`blokk-${color}`])

    const text = children || dummyText

    return (
        <span className={joinClasses(classNames)}>{text}</span>
    )

}

/**
 * 


transform: 'translateX(-100%) translateY(0%)',
backgroundSize: '100%',
opacity: '1'

width: '100%',
height: '100%',
position: 'absolute',
zIndex: '4',
animationDuration: '2.5s',
animationFillMode: 'forwards',
animationIterationCount: 'infinite',
animationTimingFunction: 'ease-in-out',
background: 'linear-gradient(to right, rgb(250, 250, 250) 10%, rgba(230, 236, 247, 0.65) 20%, rgb(250, 250, 250) 30%)',
animationName: 'fsbyhz'

 */

const shineKeyframesOld = keyframes`
    0%, 10% {
        background-position: -200px;
    }
    20% {
        background-position: top left;
    }
    90% {
        background-position: top right;
    }
    100% {
        background-position: 200px;
    }
`;

const shineKeyframes = keyframes`
    0% {
        //transform: translateX(-100%) translateY(0%);
        background-size: 3000px;
        background-position: -1000px 0%;
        //opacity: 1;
    }
    100% {
        //transform: translateX(200%) translateY(0%);
        background-size: 3000px;
        background-position: 2900px 0%;
        //opacity: 0;
    }
`;

export const BlokkTextShiny = ({children, height, fontSize, duration, borderRadius, ...props}) => {

    const sx = [
        {   
            width: '100%',
            height: height || '20px',
            display: 'inline-block', 
            fontFamily: 'blokk', 
            letterSpacing: '0.8px',
            lineHeight: '0.5',
            //color: 'rgb(250, 250, 250)',
            color: 'rgb(194 194 194 / 20%)',
            animationDuration: duration || '2.5s',
            animationFillMode: 'forwards',
            animationIterationCount: 'infinite',
            animationTimingFunction: 'ease-in-out',
            background: 'linear-gradient(to right, rgb(255, 255, 255, 0) 10%, rgba(230, 236, 247, 0.65) 20%, rgb(255, 255, 255, 0) 30%)',
            animationName: `${shineKeyframes}`,
            backgroundRepeat: 'no-repeat',
            //backgroundClip: 'text',
/*             background: 'rgb(234 234 234 / 27%) -webkit-gradient(linear, left top, right top, from(#eaeaea), to(#eaeaea), color-stop(0.5, #fff)) 0 0 no-repeat',
            backgroundSize: '150px',
            backgroundClip: 'text',
            animationName: `${shineKeyframes}`,
            animationDuration: '5s',
            animationIterationCount: 'infinite',
            textShadow: '0 0px 0px rgba(255, 255, 255, 0.5)' */
        }
    ]

    if (props.sx) {
        sx.push(props.sx)
    }

    const sxInner = {
        display: 'inline-block',
        width: '100%',
        height: height || '20px',
        backgroundColor: 'rgb(194 194 194 / 20%)',
        fontSize,
        borderRadius: borderRadius,
    }

    const text = children || dummyText

    return (<Box sx={sx} component="span">
        <Box sx={sxInner} component="span">
        </Box>
    </Box>)

}