import { Switch } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme)=>({
    root: {
      width: 40,
      height: 24,
      padding: 0,
      '&:hover': {
        boxShadow: '0 0 0 2px #eeeef4',
        borderRadius: '13px',
      },
    },
    switchBase: {
      padding: 2,
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: theme.palette.primary.light,
          opacity: 1,
        },
      },
    },
    thumb: {
      width: 20,
      height: 20,
      boxShadow: 'none',
    },
    track: {
      borderRadius: 26 / 2,
      backgroundColor: '#dedee7',
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
    rootSmall: {
      height: 20,
      width: 34,
      '& $thumb': {
        height: 16,
        width: 16,
        transform: 'translateY(-2px) translateX(-2px)',
      },
      '& $switchBase': {
        '&$checked': {
          transform: 'translateX(14px)',
        }
      },
    },
  }));

export const IOSSwitch = ( props ) => {

    const classes = useStyles();

    let rootClasses = classes.root
    if (props.size === 'small') {
      rootClasses += ' ' + classes.rootSmall
    }

    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: rootClasses,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    )

  };
  