import ListItemStyled from "modules/picasso-ui/dataviews/ListItemStyled"
import { VerticalAlignBox } from "modules/picasso-ui/layout/VerticalAlignBox"
import { createArrayOfSize } from "utils/arrayUtils"
import { orElse } from "utils/objectUtils"
import { BlokkTextShiny } from "../BlokkText"

export const LoadingListState = ({itemCount, ph, contentHeight, listItemProps, fontSize}) => {

    itemCount = itemCount || 5

    const items = createArrayOfSize(Math.min(itemCount, 100))

    const text = "..................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................."

    ph = orElse(ph, 1)

    const renderRow = (i) => {
        return (
            <ListItemStyled {...listItemProps} hoverHighlight={false} key={i}>
                <VerticalAlignBox 
                    pt={ph} 
                    pb={ph} 
                    sx={{
                        overflowX: 'hidden', 
                        maxWidth: '100%', 
                        width: '100%', 
                        '& *': {
                            paddingBottom: '10px',
                        }
                    }}
                >
                    <BlokkTextShiny fontSize={fontSize} duration="1.5s" height={contentHeight}>{text}</BlokkTextShiny>
                </VerticalAlignBox>
            </ListItemStyled>
        )
    }

    return (
        <>
            {items.map(i=>renderRow(i))}
        </>
    )
}