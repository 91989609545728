import { Box, Button, darken, Divider, FormControl, Grid, lighten, outlinedInputClasses, Select, SwitchProps, TextField, TextFieldProps, Typography } from "@mui/material";
import { FC, forwardRef, PropsWithChildren, useState } from "react";
import { IOSSwitch } from "modules/picasso-ui/form/IOSSwitch";
import { useTranslation } from "next-i18next";
import { isStringNumeric } from "utils/numberUtils";
import { orElse } from "utils/objectUtils";
import { BlokkTextShiny } from "modules/picasso-ui/present/BlokkText";
import { LoadingListState } from "modules/picasso-ui/present/content/LoadingListState";
import { LinkButtonNormal } from "modules/picasso-ui/link/LinkNormal";

const styleDef = {
    textfield: theme=>({
        [`&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error) .${outlinedInputClasses.notchedOutline}`]: {
            border: '1px solid ' + theme.palette.primary.main,
        },
        [`& .${outlinedInputClasses.notchedOutline}`]: {
            transition: '.20s ease-out',
        }
    }),
    settingsFormHeading: theme=>({
        fontSize: '18px',
        fontWeight: '600',
        paddingBottom: theme.spacing(2),
    }),
    settingsFormPropertyHeading: theme=>({
        color: theme.palette.text.secondary,
        fontSize: '14px',
        fontWeight: '500',
    }),
    settingsFormPropertyHeadingAndInfo_h: theme=>({
        color: theme.palette.greyDark.main,
        fontSize: '14px',
        fontWeight: '500',
        marginBottom: '2px',
    }),
    settingsFormPropertyHeadingAndInfo_s: theme=>({
        color: theme.palette.text.secondary,
        fontSize: '13.5px',
        fontWeight: '400',
    }),
    previewTextArea: theme=>({
        borderRadius: theme.customProps.inputBorderRadius,
        border: theme.customProps.inputBorder,
        boxShadow: 'inset 0px 0px 4px -2px #d9d8d8',
        backgroundColor: '#f3f3f3',
    }),
    propertyEditButton: theme=>({
        color: lighten(theme.palette.text.primary,0.15),
        fontWeight: '500',
        textDecoration: 'underline',
        fontSize: '14px',
        '&:hover': {
            color: darken(theme.palette.text.primary, 0.5),
        },
    }),
}

export const settingsSecondaryInfoStyle = styleDef.settingsFormPropertyHeadingAndInfo_s

export const SettingsFormHeading = ({children}) => {
    return <Typography sx={styleDef.settingsFormHeading}>{children}</Typography>
}

export const SettingsFormSection = ({children}) => {
    return (<Grid container spacing={2} sx={theme=>({marginBottom: theme.spacing(10)})}>{children}</Grid>)
}

export const SettingsFormSectionV2 = ({children}) => {
    return <div css={theme=>({marginTop: theme.spacing(2), marginBottom: theme.spacing(10)})}>{children}</div>
}

export const SettingsFormSectionHeading: FC<PropsWithChildren & { divider?: boolean }> = ({children, divider = true}) => {
    return (
        <div>
            <Typography component="h2" sx={{fontWeight: '400',  fontSize: '1.6em'}}>{children}</Typography>
            {divider && <Box mb={0} mt={2}><Divider /></Box>}
        </div>
/*         <Box mb={3}>
            <Typography 
                sx={[(theme)=>({
                    fontSize: '16px',
                    fontWeight: '500',
                    color: theme.palette.greyDark.main,//theme.palette.text.primary,
                    marginTop: theme.spacing(1),
                    marginBottom: theme.spacing(1),
                }), {...sx}]}
            >
                {children}
            </Typography>
            {subtitle && <Typography sx={{color: 'text.secondary'}}>{subtitle}</Typography>}
        </Box> */
    )
}

export const SettingsFormPropertyHeading = ({children}) => {
    return <Typography sx={styleDef.settingsFormPropertyHeading}>{children}</Typography>
}

export const SettingsFormPropertyHeadingAndInfo: FC<{title: string, subTitle?: string}> = ({title, subTitle}) => {
    return (
        <div>
            <Typography sx={styleDef.settingsFormPropertyHeadingAndInfo_h}>{title}</Typography>
            {subTitle && <Typography sx={styleDef.settingsFormPropertyHeadingAndInfo_s}>{subTitle}</Typography>}
        </div>
    )
}

export const SettingsFormSectionDivider = () => {

    return (
        <Box mb={2} mt={3}>
            <Divider />
        </Box>
    )

}

export interface SettingsFormTextAreaProps {
    minHeight?: string | number
}

export const SettingsFormTextArea = forwardRef<HTMLDivElement, SettingsFormTextAreaProps>(({ minHeight, ...props }, ref) => {

    return (
        <TextField
            ref={ref}
            multiline
            variant="outlined"
            style={{
                width: '100%',
                minHeight: minHeight || '100px',
            }}
            InputProps={{
                style: {
                    //padding: '10.5px 14px 10.5px 14px',
                    fontSize: '14px',
                },
            }}
            {...props}
        />
    );

})

/**
 * 
 * Just for showing things, e.g., like a preview
 * 
 * @param {*} param0 
 * @returns 
 */
export const SettingsFormPreviewTextArea = ({children, ...props}) => {
    return <Box p={2} sx={styleDef.previewTextArea} {...props}>{children}</Box>
}

export const SettingsFormPropertyEditButton = forwardRef<HTMLButtonElement, PropsWithChildren>(({children, ...props}, ref) => {

    const { t } = useTranslation('common')

    children = children || t('action.edit');

    return (<Button variant="text" {...props} type="button" sx={styleDef.propertyEditButton} ref={ref}>{children}</Button>)

})

export const SettingsFormSwitch = ({rerenderOnDefaultChange, ...props}:{rerenderOnDefaultChange?: boolean}&SwitchProps) => {

    let key = null;
    if (rerenderOnDefaultChange) {
        key = props.name + '_' +props.defaultChecked
    }

    return (
        <IOSSwitch key={key} {...props}/>
    )

}

export const SettingsFormTextField = forwardRef<HTMLDivElement, TextFieldProps>((props, ref) => {

    let {size, ...other} = props

    const InputProps = {
        ...props.InputProps,
        classes: {
            //@ts-ignore 'notchedOutline'
            notchedOutline: props.InputProps?.classes?.notchedOutline || null,
            focused: props.InputProps?.classes?.focused ||  null,
        }
    }

    return (
        <TextField 
            type="text"
            variant={"outlined"}
            size={size || "small"}
            fullWidth={true}
            ref={ref}
            sx={styleDef.textfield}
            {...other}
            InputProps={InputProps}
        />
    )

})

export const dataFieldInputvaluemultiplier = 'data-inputvaluemultiplier'

export interface SettingsFormNumberFieldPositiveIntegerOnlyProps {
    inputValueMultiplier?: number
    defaultValue?: number
    value?: number
}

/**
 * inputValueMultiplier: display 0,02 as 2
 */
export const SettingsFormNumberFieldPositiveIntegerOnly = forwardRef<HTMLDivElement, SettingsFormNumberFieldPositiveIntegerOnlyProps & TextFieldProps>(({inputValueMultiplier, ...props}, ref) => {

    const [error, setError] = useState<string>()

    const min = orElse(props?.inputProps?.min, 1)

    let defaultValue = props.defaultValue
    let value = props.value

    if (inputValueMultiplier && defaultValue) {
        defaultValue = defaultValue*inputValueMultiplier
    }
    if (inputValueMultiplier && value) {
        value = value*inputValueMultiplier
    }

    const inputProps = {
        min,
        ...props.inputProps
    }
    if (inputValueMultiplier) {
        inputProps[`${dataFieldInputvaluemultiplier}`] = inputValueMultiplier
    }

    return (
        <SettingsFormTextField 
            ref={ref} 
            type="number" 
            {...props}
            defaultValue={defaultValue}
            value={value}
            inputProps={inputProps}
            onChange={(e)=>{

                if (e.target.value !== '') {
                    if (parseInt(e.target.value) < min) {
                        setError(`must be positive integer (min ${min})`)
                        return;
                    }
                    if (props.inputProps?.max && parseInt(e.target.value) > props.inputProps?.max) {
                        setError('maximum: ' + props.inputProps?.max)
                        return;
                    }
                    if (!isStringNumeric(e.target.value)) {
                        setError(`must be positive integer (min ${min})`)
                        return;
                    }
                    if (parseInt(e.target.value)+'' !== e.target.value+'') {
                        setError(`must be positive integer (min ${min})`)
                        return;
                    }
                }
                setError(null)
                if (props.onChange) {
                    props.onChange(e)
                }
            }}  
            error={!!error}
            helperText={error}  
        />
    )

})


export const SettingsFormSelect = forwardRef((props, ref) => {

    return (
        <FormControl
            fullWidth={true}
            size="small"
        >
            <Select
                ref={ref}
                variant="outlined"
                fullWidth={true}
                {...props}
            />
        </FormControl>
    );

})

export const SettingsLoadingState = () => {

    return (
        <>
            <SettingsFormSection>  
                <Grid item xs={12}>
                    <SettingsFormSectionHeading>
                        <div style={{maxWidth: '300px'}}>
                        {/** @ts-ignore */}
                            <BlokkTextShiny />
                        </div>
                    </SettingsFormSectionHeading>
                </Grid>
                <Grid item xs={12}>
                     {/** @ts-ignore */}
                    <LoadingListState itemCount={3} />
                </Grid>
            </SettingsFormSection>  
        </>
    )

}

export const SettingValueEditButton = ({onClick}) => <LinkButtonNormal onClick={onClick} textSx={{textDecoration: 'underline', fontSize: '14px'}}>Edit</LinkButtonNormal>